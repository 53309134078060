import React, { useState } from 'react';
import { 
  ChevronDown, 
  Trophy, 
  Users, 
  History, 
  Star, 
  Menu, 
  X, 
  Facebook, 
  Twitter, 
  Instagram, 
  Youtube,
  Phone,
  Mail,
  MapPin
} from 'lucide-react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="fixed w-full bg-white shadow-md z-50">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex items-center">
            <span className="text-2xl font-bold text-green-600">Cricket Legacy</span>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex space-x-8">
            {['Home', 'News', 'Teams', 'Players', 'Statistics', 'Contact'].map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                className="text-gray-600 hover:text-green-600 transition-colors duration-200 font-medium"
              >
                {item}
              </a>
            ))}
          </nav>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <X className="h-6 w-6 text-gray-600" />
            ) : (
              <Menu className="h-6 w-6 text-gray-600" />
            )}
          </button>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden py-4">
            <div className="flex flex-col space-y-4">
              {['Home', 'News', 'Teams', 'Players', 'Statistics', 'Contact'].map((item) => (
                <a
                  key={item}
                  href={`#${item.toLowerCase()}`}
                  className="text-gray-600 hover:text-green-600 transition-colors duration-200 font-medium"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

const Footer = () => {
  return (
    <footer className="bg-green-800 text-white">
      <div className="max-w-6xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* About Section */}
          <div>
            <h3 className="text-xl font-bold mb-4">About Cricket Legacy</h3>
            <p className="text-gray-300">
              Dedicated to preserving and sharing the rich history and culture of cricket around the world.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-xl font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              {['About Us', 'Privacy Policy', 'Terms of Service', 'FAQ'].map((item) => (
                <li key={item}>
                  <a href="#" className="text-gray-300 hover:text-white transition-colors duration-200">
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-xl font-bold mb-4">Contact Us</h3>
            <ul className="space-y-2">
              <li className="flex items-center gap-2">
                <Phone className="h-4 w-4" />
                <span className="text-gray-300">+1 234 567 890</span>
              </li>
              <li className="flex items-center gap-2">
                <Mail className="h-4 w-4" />
                <span className="text-gray-300">info@cricketlegacy.com</span>
              </li>
              <li className="flex items-center gap-2">
                <MapPin className="h-4 w-4" />
                <span className="text-gray-300">123 Cricket Street, Sports City</span>
              </li>
            </ul>
          </div>

          {/* Social Media */}
          <div>
            <h3 className="text-xl font-bold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              {[
                { Icon: Facebook, label: 'Facebook' },
                { Icon: Twitter, label: 'Twitter' },
                { Icon: Instagram, label: 'Instagram' },
                { Icon: Youtube, label: 'Youtube' }
              ].map(({ Icon, label }) => (
                <a
                  key={label}
                  href="#"
                  className="hover:text-green-400 transition-colors duration-200"
                  aria-label={label}
                >
                  <Icon className="h-6 w-6" />
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-green-700 mt-8 pt-8 text-center">
          <p className="text-gray-300">
            © {new Date().getFullYear()} Cricket Legacy. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

const HomePage = () => {
  const [activeSection, setActiveSection] = useState('history');

  const featuredPlayers = [
    { name: 'Sir Donald Bradman', country: 'Australia', achievement: 'Highest Test batting average of 99.94' },
    { name: 'Sachin Tendulkar', country: 'India', achievement: '100 international centuries' },
    { name: 'Shane Warne', country: 'Australia', achievement: '708 Test wickets' },
  ];

  const content = {
    history: {
      title: 'Cricket History',
      text: 'Cricket has evolved from its origins in England during the 16th century to become one of the world\'s most popular sports. The first Test match was played in 1877 between Australia and England.',
    },
    teams: {
      title: 'Famous Teams',
      text: 'From the dominant West Indies of the 1970s to the Australian team of the late 1990s and early 2000s, cricket has seen many legendary teams.',
    },
    moments: {
      title: 'Historic Moments',
      text: 'From the 1932-33 Bodyline series to India\'s triumph in the 1983 World Cup, cricket has provided countless memorable moments.',
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-green-100">
      <Header />
      
      {/* Add padding-top to account for fixed header */}
      <div className="pt-16">
        {/* Hero Section */}
        <div className="relative h-96 bg-gradient-to-r from-green-600 to-green-800">
          <div className="absolute inset-0 bg-black opacity-50" />
          <div className="relative flex flex-col items-center justify-center h-full text-white px-4">
            <h1 className="text-5xl font-bold mb-4 animate-fade-in">Cricket Legacy</h1>
            <p className="text-xl text-center max-w-2xl">
              Discover the rich history and legendary moments of cricket
            </p>
            <button 
              onClick={() => {
                const element = document.getElementById('features');
                element?.scrollIntoView({ behavior: 'smooth' });
              }}
              className="mt-8 bg-white text-green-800 px-6 py-3 rounded-full font-semibold 
                       transform transition-transform duration-200 hover:scale-105 
                       flex items-center gap-2"
            >
              Explore More
              <ChevronDown className="animate-bounce" />
            </button>
          </div>
        </div>

        {/* Features Section */}
        <div id="features" className="max-w-6xl mx-auto py-16 px-4">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-200 hover:scale-105">
              <History className="w-12 h-12 text-green-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Rich History</h3>
              <p className="text-gray-600">Explore cricket's evolution from its humble beginnings to global phenomenon.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-200 hover:scale-105">
              <Trophy className="w-12 h-12 text-green-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Legendary Teams</h3>
              <p className="text-gray-600">Learn about the greatest cricket teams that dominated different eras.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-200 hover:scale-105">
              <Star className="w-12 h-12 text-green-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Iconic Moments</h3>
              <p className="text-gray-600">Relive the most memorable moments in cricket history.</p>
            </div>
          </div>
        </div>

        {/* Featured Players */}
        <div className="bg-white py-16 px-4">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-12">Featured Players</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {featuredPlayers.map((player, index) => (
                <div 
                  key={index}
                  className="bg-green-50 p-6 rounded-lg shadow-md transform transition-transform duration-200 hover:scale-105"
                >
                  <div className="flex items-center gap-4 mb-4">
                    <Users className="w-8 h-8 text-green-600" />
                    <h3 className="text-xl font-semibold">{player.name}</h3>
                  </div>
                  <p className="text-gray-600 mb-2">{player.country}</p>
                  <p className="text-sm text-gray-500">{player.achievement}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Content Section */}
        <div className="max-w-6xl mx-auto py-16 px-4">
          <div className="flex gap-4 mb-8 overflow-x-auto">
            {Object.keys(content).map((key) => (
              <button
                key={key}
                onClick={() => setActiveSection(key)}
                className={`px-6 py-2 rounded-full font-semibold transition-colors duration-200
                  ${activeSection === key 
                    ? 'bg-green-600 text-white' 
                    : 'bg-white text-green-600 hover:bg-green-50'}`}
              >
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </button>
            ))}
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">{content[activeSection].title}</h2>
            <p className="text-gray-600">{content[activeSection].text}</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
