import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import HomePage from './Home';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <HomePage/>
    </>
);
